import api from "@/assets/js/api";
import {MyConstant} from "@/assets/js/globalFunctions";
export async function validateMobile(rule, value, callback) {
  //使用正则表达式进行验证手机号码
  if (!/^1[3456789]\d{9}$/.test(value)){//英文版不验证
    return   callback(new Error(MyConstant('Lang.PleaseCorrect')+MyConstant('Lang.mobile')));
  }

  return    callback();
}
//验证国际手机函数
export async function validateWorldMobile(rule, value, callback, MobileArr) {
    // 使用动态正则表达式进行验证手机号码
    if (MobileArr.MobileRegularExp !== '' && !(new RegExp(MobileArr.MobileRegularExp)).test(value)) {
        return callback(new Error(MyConstant('Lang.PleaseCorrect') + MyConstant('Lang.mobile')));
    }
    return callback();
}
//验证是否大写字母
export async function validateBigEetter(rule, value, callback,fieldfind=false) {
    if(value){
        //如果存在大写字母则返回错误
        if (/[A-Z]/.test(value)) {
            return callback(new Error( MyConstant('Lang.noBigEetter')));
        }
        //如果存在特殊符号则返回错误
        if (/[~`!#$%^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(value)) {
            return callback(new Error(MyConstant('Lang.noSpecialSymbol')));
        }
    }
    return callback();
}
//验证是否特殊符号
export async function validateSpecialSymbol(rule, value, callback,fieldfind=false) {
    if(value){
        //如果存在特殊符号则返回错误
        if (/[~`!#$%^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(value)) {
            return callback(new Error(MyConstant('Lang.noSpecialSymbol')));
        }
    }
    return callback();
}
export async function validateUser(rule, value, callback,fieldfind=false) {
    if(value){

    let field=fieldfind.length>0?fieldfind:rule.field;
  // 发请求
    const res = await  api.ajaxbaseUrl.post('/api/User/registerVerification',
        {field_value: '{"'+field+'":"'+value+'"}'},
        {headers:{'Accept-Language':MyConstant('setApiLang')}}
    )
        .then(response => {
          return response;
        });
    if(res.data.err>0){
      callback(new Error(res.data.msg));
    }
   }
}
export async function validateSelect(rule, value, callback) {
    // console.log(rule)
    // console.log(value)
  value.length==0? callback(new Error(MyConstant('Lang.PleaseSelectOne'))):callback();
};
const validLength = function (rule, value, callback,valueLength) {
    if (!value) {
        callback();
    }

    const maxLength = typeof valueLength === 'number' ? valueLength : this;

    if (value.length <= maxLength) {
        $('.' + rule.field).css('color', '#999');
        callback();
    } else {
        $('.' + rule.field).css('color', '#F56C6C');
        return callback(new Error(MyConstant('Lang.wordLimit')));
    }
};
const validateEng= function (rule, value, callback) {


    if(value) {
        if(value.charAt(0)==' '){
            return callback(new Error(MyConstant('Lang.noIndent')))
        }
        if (value.indexOf("  ") >= 0) {
            return callback(new Error(MyConstant('Lang.noIndent')))
        }
        let huanhang =value.replace(/\n/g, '\\n');
        if (huanhang.indexOf("\\n") >= 0) {
            return callback(new Error(MyConstant('Lang.noSections')))
        }
        if (/(\/{2,}|#{1,}|\\{1,})/.test(value)) {
            return callback(new Error(MyConstant('Lang.noSpecialSymbol')));
        }
    }
    let reg = new RegExp("[\\u4E00-\\u9FFF]+","g")
    if(reg.test(value)){
        return callback(new Error(MyConstant('Lang.PleaseEnter')+MyConstant('Lang.langWord_en-us')))
    }else{
        return  callback();
    }

};

const validateCn= function (rule, value, callback) {

    if(value) {

        if(value.charAt(0)==' '){

            return callback(new Error(MyConstant('Lang.noIndent')))
        }


        if (value.indexOf("  ") >= 0) {
            return callback(new Error(MyConstant('Lang.noIndent')))
        }

        let huanhang = value.replace(/\n/g, '\\n');
        if (huanhang.indexOf("\\n") >= 0) {
            return callback(new Error(MyConstant('Lang.noSections')))
        }
    }
    return  callback();


};

// 导出你的接口方法
export default {
    validateMobile,
    validateWorldMobile,
    validateUser,
    validateBigEetter,
    validateSpecialSymbol,
    validateSelect,
    validateCn,
    validateEng,
    validLength
};
