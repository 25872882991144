<template>
  <Components :datas='{
        "Template/Header": "",
      }'>
  </Components>
  <div class="middle">
<!--      //创建10个div-->
    <div v-slide-in class="slide" v-for="(item, index) in 20" :key="index">
      {{ item }}
    </div>

  </div>

  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";

export default {
  components: {
    Components
  },
  data() {
    return {}
  },
  async created() {

  },
  methods: {}
};
</script>
<style scoped>
.slide{
  width: 100%;
  height: 100px;
  background: #ccc;
  margin-bottom: 20px;
  text-align: center;
  line-height: 100px;
}
</style>
