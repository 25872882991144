<template>

  <Components :datas= '{
        "Template/Header": "",
      }'>
  </Components>
  <img style="width: 100%;height: 112px;" src="../../../assets/img/sch.png">

  <div class="middle">
    <div class="Pagetitle">
      {{ MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.famous['name_'+MY_CONSTANT.sqlWhereLang]}}<el-icon><DArrowRight /></el-icon>
      <span>{{this.$route.params.year}}{{MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.famous['name_'+MY_CONSTANT.sqlWhereLang]}}</span>
    </div>

    <el-row class="demo-autocomplete">
      <el-col :span="24">
        <el-autocomplete style="width: 100%;margin:10px 0px; "

            v-model="state1"
            :fetch-suggestions="querySearch"
            clearable
            :placeholder="MY_CONSTANT.Lang.CompanyName"
            @select="handleSelect"
            fit-input-width="true"
        />
      </el-col>
    </el-row>

<!--    <input name="cp_name" v-model="keyword" type="text" id="cp_name" value="" :placeholder="this.$MyConstant('Lang.CompanyName')">-->
<!--    <br><br>-->

<!--      <el-select  style="width: 100%;"  v-model="nature" :placeholder="this.$MyConstant('Lang.CompanyProperty')" >-->

<!--        <el-row  v-if="MY_CONSTANT.sqlWhereLang=='cn'" v-for="(el,index) in MY_CONSTANT.Api_CONSTANT.DimensionLists.company_nature" :key="index">-->
<!--          <el-col v-if="index % 2 === 1" :span="12">-->
<!--            <el-option :label="MY_CONSTANT.Api_CONSTANT.DimensionLists.company_nature[index-1].nature" :value="MY_CONSTANT.Api_CONSTANT.DimensionLists.company_nature[index-1].nature"></el-option>-->
<!--          </el-col>-->
<!--          <el-col  v-if="index % 2 == 1 || (index % 2 == 0 && index == MY_CONSTANT.Api_CONSTANT.DimensionLists.company_nature.length-1)"  :span="12">-->
<!--            <el-option :label="el.nature" :value="el.nature+index"></el-option>-->
<!--          </el-col>-->
<!--        </el-row>-->

<!--        <el-row  v-if="MY_CONSTANT.sqlWhereLang=='en'">-->
<!--          <el-col :span="24">-->
<!--            <el-option label="State-Owned Enterprises"  :value="'State-Owned Enterprises'"> </el-option>-->
<!--            <el-option label="Domestic-Funded Private Enterpries"  :value="'Domestic-Funded Private Enterpries'"> </el-option>-->
<!--            <el-option label="Sino-Foreign Equity Joint Ventures"  :value="'Sino-Foreign Equity Joint Ventures'"> </el-option>-->
<!--            <el-option label="Wholly Foreign-Owned Enterprises"   :value="'Wholly Foreign-Owned Enterprises'"> </el-option>-->
<!--            <el-option label="Hong Kong, Macao, Taiwan-Invested Enterprises"   :value="'Hong Kong, Macao, Taiwan-Invested Enterprises'"> </el-option>-->
<!--          </el-col>-->
<!--        </el-row>-->


<!--    </el-select>-->
<!--    <br><br>-->
<!--    <select id="hall" v-model="hall" name="hall">-->
<!--      <option value="">&#45;&#45;{{ this.$MyConstant('Lang.hall') }}&#45;&#45;</option>-->
<!--      <option value="1" selected="selected">1{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="2">2{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="3">3{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="4">4{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="5">5{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="6">6{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="7">7{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="8">8{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="9">9{{ this.$MyConstant('Lang.hallNum') }}</option>-->
<!--      <option value="10">{{ this.$MyConstant('Lang.FloorTwo') }}</option>-->
<!--      <option value="11"> {{ this.$MyConstant('Lang.hall5Two') }}</option>-->
<!--      <option value="12"> {{ this.$MyConstant('Lang.hall6Two') }}</option>-->
<!--    </select>-->
<!--    <br><br>-->
<!--    <button style="font-size: 17px;" @click="handleSearchClick" class="sub_search" name="sub">{{ this.$MyConstant('Lang.select') }}</button>-->
<!--<br><br>-->

  <!--          v-if="RExhibitors.length > 0"-->
  <h2 v-if="RExhibitors.length > 0">{{ this.$MyConstant('Lang.RecommendedExhibitors') }}</h2>
  <div v-if="RExhibitors.length > 0" class="list-muem">
    <ul>
      <li v-for="item in RExhibitors">
        <custom-link :to="'/FamousInfo/'+this.$route.params.year+'/'+this.$encryptString(item.user_id)">
          {{ this.$MyConstant('sqlWhereLang')=='cn'?item.cp_name :item.cp_en_name  }}
        </custom-link>
      </li>
    </ul>
  </div>



  <h2>{{ this.$MyConstant('Lang.ExhibitorList') }}</h2>

  <div class="list-muem">
    <ul>
      <li v-for="item in Exhibitors">
        <custom-link :to="'/FamousInfo/'+this.$route.params.year+'/'+this.$encryptString(item.user_id)">
          {{ this.$MyConstant('sqlWhereLang')=='cn'?item.cp_name :item.cp_en_name  }}
        </custom-link>
      </li>
    </ul>
  </div>

  <div class="pages" v-if="Exhibitors.length > 0">
    <a @click="OnPage(backPage)"><span class="back"><el-icon><ArrowLeftBold /></el-icon></span></a>
    <a :class="{'current':item==page}" @click="OnPage(item)" v-for="item in pageNum">{{item}}</a>
    <a @click="OnPage(nextPage)">
      <span class="next"><el-icon><ArrowRightBold /></el-icon></span>
    </a>
    </div>
    <div v-else><el-text>{{ MY_CONSTANT.Lang.Nodata }}</el-text></div>
</div>




<br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>
</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      state1:'',

      nature:'',
      hall:'',
      value: [], // 选中的值
      options: [], // 下拉选项
      loading: false, // 加载状态
      RExhibitors:[],
      Exhibitors:[],
      keyword:this.$route.query.keyword??'',
      page:1,
      limit:11,
      limit_page:5,
      backPage:1,
      nextPage:2,
      pageNum:'',
      suggestions:'',
    }
  },
  async created() {
     this.RExhibitors = await api.ajaxbaseUrlData('/api/User/InvitationLetterListSearch',{filter:JSON.stringify({is_recommend:1})});




  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
     async handler() {

        this.limit_page=5
        this.fetchData()

      }
    },
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.suggestionsFun();
      }
    }
  },
  methods: {
    // 实现获取自动补全建议的逻辑
   async suggestionsFun() {
    const suggestion = await api.ajaxbaseUrlData('/api/User/InvitationLetterListSearch', {limit: 'all'});

    this.suggestions = suggestion.map((item) => {
      return {
        value: this.MY_CONSTANT.sqlWhereLang == 'cn' ? item.cp_name : item.cp_en_name,
        link: `/FamousInfo/${this.$route.params.year}` + '/' + `${this.$encryptString(item.user_id)}`
      };
    });


  },
    querySearch(queryString, callback) {

      const results = queryString
          ? this.suggestions.filter((item) => item.value.toLowerCase().includes(queryString.toLowerCase()))
          : this.suggestions;

      callback(results);
    },
    handleSelect(ere){
      this.$router.push('/'+this.MY_CONSTANT.setApiLang+ere.link)
      // console.log(ere.link);
    },

    async fetchData() {
      // cat_id:this.$route.query.cat_id??false,
      let where=this.$getApiWhere({
        limit: this.limit,
        page: this.page,
        order: '{"user_id":"desc"}',
        filter:'{"cp_name|cp_en_name":"' + this.keyword + '","cpse_exhibition.hall":"' + this.hall + '","nature":"' + this.nature + '"}',
        op: '{"cp_name|cp_en_name":"%*%","hall":"=","nature":"="}',
      });
      if(this.$route.params.year){ where['year']=this.$route.params.year;}
      const response = await      api.ajaxbaseUrlget(`/api/User/InvitationLetterListSearch`,where).finally(() => {
            // 请求完成后，设置加载状态为 false
            this.loading = false;
             });
      // if(response.data.obj.data.length==0){
      //
      //   this.$message.warning({
      //     message: this.MY_CONSTANT.Lang.Nodata
      //     offset:250,duration:3000
      //   });
      //   return false;
      // }
      this.Exhibitors = response.data.obj.data;
      // this.count = response.data.obj.data.totalCount
      let  all_page = Math.ceil(response.data.obj.totalCount/this.limit);//总页数  向上取余ceil()  向下取余floor()

      if(all_page < this.limit_page){
        //如果总页数小于页面显示的页码数  则页面显示的页码数=总页数
        this.limit_page = all_page;
      }

      let  floor_page = Math.floor(this.limit_page/2);//求得中间页 向下取余floor()  2 23 4 56
      let start1_page = this.page - floor_page;//4 - 2
      let  end_page = this.page + floor_page;//4 + 2

      if(start1_page < 1){
        //如果假如 左边的页码小于1 那我们就从1开始显示$limit_page
        start1_page = 1;
        end_page = this.limit_page;

      }

      if(end_page > all_page){
        //如果假如 右边的页码大于我们的总页码  那我们就从总页码开始-显示页+1
        end_page = all_page;
        start1_page = all_page - this.limit_page +1;
      }

      let  pageNum=[];
      this.backPage = this.page-1==0?1:this.page-1;

      let i,page=0;
      for(i=start1_page;i<=end_page;i++){
        pageNum[page++]=i
      }
      this.pageNum=pageNum
      this.nextPage = (all_page-this.page==0)?all_page:(this.page+1);

    }
    ,
    OnPage(page){
      this.page=page
      this.fetchData();
    }
    ,
    handleSearchClick() {
      this.page=1
      this.limit_page=this.limit_page
      // alert(this.keyword.trim());
      // if (this.keyword.trim() !== "") {
        this.fetchData()
      // }
    }

  }
};
</script>
<style scoped>
/deep/.el-input__inner{height: 35px;}
.sub_search{
  background-color: var(--uavdeep-color);
  color: #fff;
  width: 160px;
  height: 39px;
}
.list-muem{
  line-height: 35px;
}

/* 翻页 */
.pages {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  font-family: arial;
}

.pages a {
  display: inline-block;
  margin: 0 5px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #ededed;
  font-size: 14px;
}

.pages a .back,
.pages a .next {
  background: #333;
  color: #fff;
  display: block;

  width: 35px;
  height: 35px;
}

.pages a:hover .back,
.pages a:hover .next {
  background: var(--uavdeep-color);
}

.pages a .back,
.pages a:hover .back {
  background-position: 6px -311px;
}

.pages a .next,
.pages a:hover .next {
  background-position: 7px -339px;
}

.pages a.current {
  color: #fff;
  background: #c5c5c5;
}
</style>
