<template>
  <Components :datas='{
        "Template/Header": "",
        "Template/PageMenu": {Pagetitle: [
          MY_CONSTANT.Api_CONSTANT.menu.Usermenu.exhibition.list.reserve["name_"+MY_CONSTANT.sqlWhereLang]
        ]}
      }'>
  </Components>

  <div class="content">
  <el-form  label-position="top" v-if="this.$cookies.isKey('Cookie')" :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">
    <el-form-item size="large" :label="MY_CONSTANT.Lang.CCompanyName" prop="company">
      <!--                        '[^\\u4e00-\\u9fa5]': '',-->
      <el-input  @keyup.native="strReplace('cp_name',{' ': ''})" v-model="ruleForm.company" :placeholder="MY_CONSTANT.Lang.exampleCCompanyName" onkeyup="" ></el-input>

    </el-form-item>
    <el-form-item size="large" :label="MY_CONSTANT.Lang.ECompanyName" prop="company_en">

      <el-input   v-model="ruleForm.company_en" @keyup.native="strReplace('company_en',{'[\\u4E00-\\u9FA5]': ''})" :placeholder="MY_CONSTANT.Lang.exampleECompanyName"></el-input>

    </el-form-item>


    <el-form-item size="large" :label="MY_CONSTANT.Lang.linkman" prop="linkman">
      <el-input  v-model="ruleForm.linkman"    :placeholder="MY_CONSTANT.Lang.PleaseEnter"    ></el-input>
      <!--                        @keyup.native="strReplace('linkman',{'[^\\u4e00-\\u9fa5A-Za-z]': ' ',' ': ''})"-->
    </el-form-item>


    <el-form-item size="large" :label="MY_CONSTANT.Lang.mobile" prop="mobile">
      <el-row>
        <el-col :span="15">
          <el-select-v2
              v-model="ruleForm.mobile_quhao"
              :options="worldJson"
              :props="{
                      label: 'country_name',
                      value: 'country_code',
                    }"
              placeholder="Please select"
              style="width: 100%"
              filterable
          />
        </el-col>
        <el-col :span="9">
          <el-input v-model="ruleForm.mobile" @keyup.native="strReplace('mobile',{'\\D': ' ',' ': ''})" :placeholder="MY_CONSTANT.Lang.PleaseEnter"></el-input>
        </el-col>
      </el-row>
    </el-form-item>

    <el-form-item label="E-mail" prop="email">
      <el-input size="large" v-model="ruleForm.email" :placeholder="MY_CONSTANT.Lang.PleaseEnter" @keyup.native="strReplace('email',{' ': ''})"></el-input>
    </el-form-item>

    <el-form-item size="large" :label="MY_CONSTANT.Lang.quantity" prop="quantity"   class="quantity"   >
      <p style="width:100%;margin:5px 0px;font-size: 12px;font-weight: normal;line-height: normal;letter-spacing: 0em;color: rgba(0, 0, 0, 0.4);">{{MY_CONSTANT.Lang.quantityDesc}}</p>
      <el-input-number style="width: 150px" v-model="ruleForm.quantity" :min="1" :max="100"></el-input-number><br>
    </el-form-item>


<!--    <el-form-item size="large" :label="MY_CONSTANT.Lang.intention_pavilion" prop="intention_pavilion" class="Linheight28">-->

<!--      <el-radio-group v-model="ruleForm.intention_pavilion">-->
<!--        <el-radio v-for="item in this.$MyConstant('Lang.intention_pavilion_arr"  style="width: 100%;margin: 7px;" size="large" :label="item" name="Intention_pavilion"></el-radio>-->
<!--      </el-radio-group>-->
<!--    </el-form-item>-->
    <el-button v-if="this.$MyConstant('Api_CONSTANT.SizeConfig.site_pre_reg_closed')== 0 " style="width: 100%;margin-top:41px;"  size="large"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.submit }}</el-button>
  </el-form>
</div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>


</template>

<script>
import Components from '../../../modules/Components.vue'
import {validateWorldMobile,validateUser,validateSelect} from "@/assets/js/validation";
import api from "@/assets/js/api";

export default {
  components: {
    Components
  },
  data() {
    return {
      worldJson:[],
      ruleForm:{
        company:this.$getCookie('cp_name'),
        company_en:this.$getCookie('cp_en_name'),
        linkman:this.$getCookie('link_man'),
        mobile:this.$getCookie('mobile'),
        mobile_quhao:this.$getCookie('mobile_quhao'),
        email:this.$getCookie('email'),
        quantity:'1',
        intention_pavilion:'',

      },
      rules: {
        company: [
          { required: this.$MyConstant('sqlWhereLang')=='cn', message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CCompanyName'), trigger: 'blur'},
          { validator:  (rule, value, callback) => validateUser(rule, value, callback, 'cp_name'), trigger: 'blur' }
        ],
        company_en: [
          { required: true, message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.ECompanyName'), trigger: 'blur'},
          { validator: (rule, value, callback) => validateUser(rule, value, callback, 'cp_en_name'), trigger: 'blur' }
        ],

        quantity: [
          { required: true, message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.quantity'), trigger: 'blur'},
          {pattern: /\d+$/, message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Num'),trigger: ['change','blur']},
        ],
        linkman: [
          { required: true, message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.linkman'), trigger: 'blur'},
        ],
        mobile: [
          { required: true, message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.mobile'), trigger: 'blur' },
          { validator:  (rule, value, callback) => validateWorldMobile(rule, value, callback,this.worldJson.find(item => item.country_code === this.ruleForm.mobile_quhao)), trigger: 'blur' },
        ],

        email: [
          { required: true, message: ()=>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message: ()=>this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: 'blur' },
          { validator: validateUser, trigger: 'blur' }
        ],
        intention_pavilion: [
          {required: true, message: ()=>this.$MyConstant('Lang.PleaseSelectOne'), trigger: ['change','blur'] },
          { validator: validateSelect, trigger: 'blur' },
        ],


      }
    }
    },
  watch: {
  'MY_CONSTANT.sqlWhereLang': {
  immediate: true, // 初始化时立即执行
    async handler() {

      this.rules.company[0].required=this.$MyConstant('sqlWhereLang')=='cn'

        const worldJson = await import(`../../../lang/world.json`);
        this.worldJson = worldJson.default.map((item) => {
          return {
            country_code: item.country_code,
            MobileRegularExp: item.MobileRegularExp,
            country_name: item['country_name_'+this.MY_CONSTANT.sqlWhereLang]+'('+item.country_code+')',
          };
        });

   }
  }
  },
 async created() {
   if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_pre_reg_closed')==1){
     this.$message.error({
       message: this.$MyConstant('Lang.site_pre_reg_closed'),
           offset:250,duration:3000
     });
   }
    const response= await  api.ajaxbaseUrlget('/api/BoothsReserve/info')
   if(response&&response.data.err==0){this.ruleForm=response.data.obj.data}
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.ajaxbaseUrl.post('/api/BoothsReserve/submit',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {

                  this.$message.success({
                    message: response.data.msg,
                    offset:250,duration:3000
                  });

              });
        }else {

          this.$nextTick(() => {
            // 获取错误节点
            let isError = this.$refs[formName].$el.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })

          return false;
        }
      });

    },


  }
};
</script>

<style scoped>
.content{
  padding: 21px 26px;
}


</style>
