<template>


  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.login.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">

      <!-- 左边 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">
        <el-form-item :label="MY_CONSTANT.Lang.Account+'：'" prop="user_name">
          <el-input class="input400" v-model="ruleForm.user_name" :placeholder="MY_CONSTANT.Lang.AllLoginField" ></el-input>
        </el-form-item>

        <el-form-item :label="MY_CONSTANT.Lang.Password+'：'" prop="password">
          <el-input class="input400" v-model="ruleForm.password" :placeholder="MY_CONSTANT.Lang.PleaseEnter" show-password></el-input>
        </el-form-item>

        <el-form-item :label="'\n'" >
          <el-button type="danger"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.login }}</el-button>
          <custom-link :to="this.$route.query.s?'/UserRegister?s='+this.$route.query.s:'/UserRegister'" style="padding-left: 15px;"><el-button type="danger"    > {{ MY_CONSTANT.Lang.register }}</el-button></custom-link>
          <br><br>
          <div style=""><custom-link style="color: #b01e22;" to="/UserForgotPassword" class="forget_password">
            {{ MY_CONSTANT.Lang.forgetAP }}
          </custom-link>
          </div>
        </el-form-item>

      </el-form>
      </div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in menu">
          <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
import {ElMessage} from "element-plus";
import {decryptString} from "@/assets/js/globalFunctions";
export default {
  components: {
    Components
  },
  data() {
    return {
      newsInfo:[],
      menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list'),
      JumpPath:this.$route.query.s?this.$decryptString(this.$route.query.s):'/UserEdit',
      ruleForm: {
        user_name: '',
        password: '',

      },
      rules: {

        user_name: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.AllLoginField'), trigger: 'blur'}
        ],

        password: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Password'), trigger: 'blur' },
          { min: 6, max: 30, message: () => this.$MyConstant('Lang.PasswordLength'), trigger: 'blur' },

        ]


      }
    }
  },
  created() {

//如果登录就直接跳
    let that=this

    if(this.$getCookie()){

      this.$message.success({message:this.$MyConstant('Lang.loginEd'),offset:400,duration:3000});
      setTimeout(function (){that.$router.push('/'+that.MY_CONSTANT.setApiLang+that.JumpPath) },2000)

    }
  },
  methods: {
    Jump(){

    },
   async submitForm(formName) {
  let that =this

     await this.$refs[formName].validate((valid) => {
        if (valid) {
          api.ajaxbaseUrl.post('/api/User/Login',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                if(response&&response.data.err==0){

                  this.$cookies.set("Cookie", response.data.obj.data, this.$MyConstant('Login.expires'));
                  this.$message.success({message:this.$MyConstant('Lang.loginSuccess'),offset:400,duration:3000});
                  setTimeout(function (){ window.location.href='/'+that.MY_CONSTANT.setApiLang+that.JumpPath;},2000)

                }
              })
              .catch(error => {
                // 处理错误
                console.log(error);
              })


        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },

  }
};
</script>
<style scoped>

</style>
