import axios from 'axios';
import {equipment, getCookie} from './globalFunctions.js';

const Api_CONSTANT = {
    baseUrl: process.env.NODE_ENV === 'development'
        ? '//api.china-drone.com.cn/' // 开发环境下的接口路径
        : '//api.china-drone.com.cn/',// 生产环境下的接口路径
    baseApiHeaders: {
        "Authorization": 'Bearer VUETXDKZVKXF7NZ0N91RHSDGOB0R39AK',
        "access-token": getCookie('token'),
        "Accept-Language": localStorage.getItem("Accept-Language") ?? 'zh-cn'
    },
    baseApiCache: {
        setCache: process.env.NODE_ENV === 'development' ? false : false, //是否开启Api接口缓存
        expires: 3600,//缓存时间

    }
}

async function SizeConfig() {
    return $cookies.isKey("SizeConfig") && Api_CONSTANT.baseApiCache.setCache
        ? JSON.parse(localStorage.getItem("SizeConfig"))
        : await axios.get(Api_CONSTANT.baseUrl + '/api/public_view/SizeConfig', {
            headers: Api_CONSTANT.baseApiHeaders
        }).then(response => {
            $cookies.set('SizeConfig', true, Api_CONSTANT.baseApiCache.expires);
            localStorage.setItem('SizeConfig', JSON.stringify(response.data.obj.data));
            return response.data.obj.data;
        });
}

async function menu() {
    //获取菜单
    return $cookies.isKey("menu") && Api_CONSTANT.baseApiCache.setCache
        ? JSON.parse(localStorage.getItem("menu"))
        : await axios.get(Api_CONSTANT.baseUrl + '/api/public_view/menu', {
            headers: Api_CONSTANT.baseApiHeaders
        }).then(response => {
            $cookies.set('menu', true, Api_CONSTANT.baseApiCache.expires);
            localStorage.setItem('menu', JSON.stringify(response.data.obj.data));
            return response.data.obj.data;
        });
}

async function DimensionLists() {
    return $cookies.isKey("DimensionLists") && Api_CONSTANT.baseApiCache.setCache
        ? JSON.parse(localStorage.getItem("DimensionLists"))
        : await axios.get(Api_CONSTANT.baseUrl + '/api/public_view/DimensionLists', {
            headers: Api_CONSTANT.baseApiHeaders
        }).then(response => {
            $cookies.set('DimensionLists', true, Api_CONSTANT.baseApiCache.expires);
            localStorage.setItem('DimensionLists', JSON.stringify(response.data.obj.data));
            return response.data.obj.data;
        });
}

async function AllApiConfig() {
    let data = await Promise.all([
        SizeConfig(),
        menu(),
        DimensionLists()]);
    return Object.assign({
        SizeConfig: data[0],
        menu: data[1],
        DimensionLists: data[2]
    }, Api_CONSTANT);
}

const lang = localStorage.getItem("Accept-Language") ? localStorage.getItem("Accept-Language") : 'zh-cn';
const Tytime = (new Date())
export const MY_CONSTANT = {
    Equipment:navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        ? 'mobile' : 'pc',
    Api_CONSTANT: await AllApiConfig(),
    CurrentYear: Tytime.getFullYear(),
    setApiLang: lang,
    apiLangArray:['zh-cn', 'en-us'],
    Lang: await import(`../../lang/${lang}.json`),
    sqlWhereLang: lang == 'zh-cn' ? 'cn' : 'en',//旧数据查询用到的
    // 设置API的基础URL
    User: {
        defaultLogo: 'https://cpsmedia.oss-cn-shenzhen.aliyuncs.com/upload/20231225/4041780546d633eb31f8dd6e2ad92f10.png',
    },
    Login: {
        expires: 86400,//登录过期时间
    },
    Upload: {
        logo: {
            size: 1,//M为单位
            width: 300,
            height: 300
        },
        IdPhoto: {
            size: 2,//M为单位
            width: 300,
            height: 400
        }
    },
    StaticPath: process.env.NODE_ENV === 'development'
        ? 'http://admin.china-drone.com.cn/' // 开发环境下的静态资源路径
        : 'http://admin.china-drone.com.cn/', // 开发环境下的静态资源路径
    defaultNewLogo:'//admin.china-drone.com.cn/UploadFiles/Images/Article/1630482831330053010.png',
    // baseUrl:process.env.NODE_ENV === 'development'
    //   ? 'http://api.china-drone.com.cn/' // 开发环境下的接口路径
    //   : 'http://api.china-drone.com.cn/', // 生产环境下的接口路径
    // apiHeaders:{Authorization:'Bearer VUETXDKZVKXF7NZ0N91RHSDGOB0R39AK'},
    //查询条件默认
    getWhere: {
        op: '=',
        limit: '10',
        page: 1,
        order: '',
// {"add_time":"desc"}
        filter: '',
    },
//菜单
    //微信分享默认
    shareWx: {
        title: '无人机',
        imageUrl: '//usysresearch.com/html/img/logo2.png',
        description: '深圳市中安无人系统研究院 (Shenzhen CPS Unmanned System ResearchInstitute，简称:USYS Research) 成立于2015年，' +
            '是由深圳市民政局批准成立的民办非企业单位，是面向全国地区开展智能网联无人系统产业与应用服务的产业型专业调研服务机构。' +
            '深圳市政协委员、深圳市智能交通行业协会常务副会长杨鹏博士任院长。 USYS Research秉持“立足深圳，依托粤港澳大湾区发展优势，开展全球无人系统领域的交流与合作”的服务视野和定位，致力于无人系统的科技融合与创新应用，着力联合科研院所、高等院校、知名科技企业和服务机构，共同开展面向陆海空天大无人系统产业的智库建设与项目服务、产业高端对接、产学研合作及产业报告编制等事务。',

    },

};
// console.log(MY_CONSTANT);
