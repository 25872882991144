<template>

  <Components :datas='{
        "Template/Header": "",
        "Template/PageMenu": {Pagetitle: [
          MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.visitor["name_"+MY_CONSTANT.sqlWhereLang],
          MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.visitor.list.floorplan["name_"+MY_CONSTANT.sqlWhereLang]
        ]}
      }'>
  </Components>

  <div class="middle">


    <el-row :gutter="24">
      <el-col :span="8" v-for="(item,index) in floorplanItems"
              style="display: flex;align-items: center;justify-content: center;max-width:100%;">
        <div style="">
          <custom-link   :style="{ color: item.id == (this.$route.query.id||defaultId) ? '#fff' : '#8B8B8B' }" :to="'/Floorplan?id='+item.id">
            <el-button  :disabled="item.id == (this.$route.query.id||defaultId)" color="var(--uavshallow-color)">{{ item.img_name }}</el-button>
          </custom-link>
        </div>
      </el-col>
    </el-row>


    <div style="margin-top: 20px;" ref="imageContainer">
      <img  style="width: 100%;height: auto;"  :src="floorplanImg">
    </div>

  </div>

  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>
</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";

export default {
  components: {
    Components
  },
  data() {
    return {
      defaultId:this.$MyConstant('sqlWhereLang')=='cn'?68:78,
      content: '',
      menu: this.$MyConstant('Api_CONSTANT.menu.PublicMenu.visitor.list'),
      floorplanItems:[],
      floorplanImg:'',
    }
  },
  async created() {
    // console.log(this.$route)
  },
  methods: {
    async fetchData() {
      const response = await api.ajaxbaseUrlget('/api/Floorplan/GetFloorplan');

      let pageItem=[],page=0;

      // if(!this.$route.query.id){
      //   this.floorplanImg= this.$MyConstant('StaticPath')+response.data.obj.data[0].pic
      // }
      // this.floorplanItems = response.data.obj.data.filter((item) => {
      //   return item.cat_id === 1 && item.lang === this.MY_CONSTANT.sqlWhereLang;
      // });
      let showId=this.$route.query.id||this.defaultId;

      response.data.obj.data.map((item, index) => {

        if (item.cat_id== 1&&item.lang==this.MY_CONSTANT.sqlWhereLang) {
          pageItem[page]=item;
          //图片
          if(showId==item.id){
            this.floorplanImg=item.pic.includes('http')?item.pic:this.MY_CONSTANT.StaticPath+item.pic
          }
          page++
        }

      });
      this.floorplanItems = pageItem;
      this.$nextTick(() => {
        new this.$viewer(this.$refs.imageContainer);
      });
    }
  },
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    },
    'MY_CONSTANT.sqlWhereLang': {
      handler() {
        this.defaultId=this.MY_CONSTANT.sqlWhereLang=='cn'?68:78;
        this.$router.push('/'+this.MY_CONSTANT.setApiLang+'/Floorplan?id='+this.defaultId)
      }
    }
  },
};
</script>

<style scoped>

/* 1.观众登记 */

/* 2展位图 serve_map*/
.serve_map-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 134px;
}

.serve_map-left {
  width: 75%;
  margin-top: 47px;

}
.serve_map-left ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;

}

.serve_map-left ul li {
  width: 10%;
  text-align: center;
  padding: 14px 0 11px;
  background-color: #F5F5F5;
  margin-right: 8px;
  margin-bottom: 6px;

  font-size: 13px;

  font-weight: 500;

  color: #8B8B8B;
  line-height: 35px;

}

.act {
  color: #fff !important;
  background-color:var(--uavmedium-color) !important;
}

.serve_map-left .tab .tab1 {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.serve_map-left .tab .tab1::after {
  content: "";
  display: block;
  width: 209px;
  height: 118px;
  visibility: hidden;
}

.serve_map-left .tab .tab1 div {
  /* width: 209px;
  height: 118px;
  margin-bottom: 10px;
  margin-right: 10px; */
}

.serve_map-left .tab .tab1 img {
  width: 100%;
}

</style>
