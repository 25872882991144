<template>

  <Components :datas='{
        "Template/Header": "",
        "Template/PageMenu": {Pagetitle: [
          MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.download["name_"+MY_CONSTANT.sqlWhereLang],
          MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.download.list.shouce["name_"+MY_CONSTANT.sqlWhereLang]
        ]}
      }'>
  </Components>

  <div class="middle">

      <!-- 左边 -->
      <div class="contentLeft">

        <div class="download-left1" v-for=" item in DownloadList">
          <div class="download_hd">
            <img :src="this.$MyConstant('StaticPath')+item.pic">
          </div>
          <div class="download_hd2">
            <div>
              <h2>{{ item.title }}</h2>
              <em>{{ item.decr }}</em>
            </div>
          </div>
          <a title="MY_CONSTANT.Lang.Download" :href="item.fileurl">
            <em>{{ MY_CONSTANT.Lang.Download }}</em>
          </a>
        </div>

    </div>

  </div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>


</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      DownloadList:[],
    }
  },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },

  methods: {
   async fetchData() {

      const response = await api.ajaxbaseUrlget('/api/public_view/Download',{class_id:this.$route.query.class_id,lang:this.$MyConstant('sqlWhereLang')});
      this.DownloadList=response.data.obj.data
    }

  }
};
</script>
<style scoped>
.download-left1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.download-left1 .download_hd{
  width: 31%;

}
.download-left1 .download_hd img{
  width: 100%;

  /* border: 2px solid #007AFF; */

}
.download-left1  .download_hd2{
  width: 66%;
  border-bottom: 2px solid #8B8B8B;
  padding: 10px;

}
.download-left1 .download_hd2 h2{

  opacity: 1;
  font-size: 12px;
  font-weight: 500;
  color: #000000;

}
.download-left1 .download_hd2 em{

  opacity: 1;
  font-size: 10px;
  font-weight: 500;
  color: #bebebe;
  line-height: 32px;
  margin-bottom: 14px;

}
.download-left1  a{
  width: 60px;
}
.download-left1  a em{

  padding:2px 5px;
  background-color: #000;
  opacity: 1;
  font-size: 12px;
  font-family: Source Han Sans CN, Source Han Sans CN-Bold;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;

}


</style>
