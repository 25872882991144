<template>

  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>

    <div class="about">
      <div class="about-top">
        <p>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
        <img src="../../../assets/img/icon/jiantou.png">
        <em>{{ this.$MyConstant('Api_CONSTANT.menu.PublicMenu.exhibitor_info.list.media.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

      </div>
      <div class="contentMiddle">
        <!-- 左边 -->
        <div class="contentLeft">

        <el-form    :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">


      <el-form-item :label="MY_CONSTANT.Lang.media_organization_name+'：'" prop="organization_name">
        <el-input size="large" class="my-input" v-model="ruleForm.organization_name" :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.media_organization_name" ></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.media_type+'：'" prop="type">
        <el-select v-model="ruleForm.type" :placeholder="MY_CONSTANT.Lang.PleaseSelect+MY_CONSTANT.Lang.media_type">
            <el-option v-for="(el, index) in MY_CONSTANT.Lang.media_typeArr" :label="el" :value="el"> </el-option>
        </el-select>
      </el-form-item>


          <div style="width: 270px;text-align: right;display: inherit; ">
            <label style="font-size: 18px;font-weight: normal;line-height: 26px;letter-spacing: 0em;">{{ MY_CONSTANT.Lang.reporterInformation }}</label>
          </div>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.name+'：'" prop="name">
        <el-input class="input307" v-model="ruleForm.name"    :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.name"    ></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.position+'：'" prop="position">
        <el-input class="input307" v-model="ruleForm.position"    :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.position"    ></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="this.$MyConstant('Lang.mobile')+'：'" prop="mobile">
        <el-input class="input307" v-model="ruleForm.mobile" @keyup="strReplace('mobile',{'\\D': ' ',' ': ''})"  :placeholder="this.$MyConstant('Lang.PleaseEnter')"></el-input>
      </el-form-item>

      <el-form-item size="large"  label="E-mail" prop="email">
        <el-input class="input307" v-model="ruleForm.email" :placeholder="this.$MyConstant('Lang.PleaseEnter')" @keyup="strReplace('email',{' ': ''})"></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.media_reporter_number+'：'" prop="reporter_number">
        <el-input class="input307" v-model="ruleForm.reporter_number"    :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.media_reporter_number"    ></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.media_id_type+'：'" prop="id_type">
        <el-select v-model="ruleForm.id_type" :placeholder="MY_CONSTANT.Lang.PleaseSelect+MY_CONSTANT.Lang.media_id_type">
          <el-option v-for="(el, index) in MY_CONSTANT.Lang.media_id_typeArr" :label="el" :value="el"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.id_number+'：'" prop="id_number">
        <el-input class="input307" v-model="ruleForm.id_number"    :placeholder="MY_CONSTANT.Lang.PleaseEnter+MY_CONSTANT.Lang.id_number"    ></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.pressCard+'：'"  prop="press_card" class="el-form-item-display-block">

        <el-button size="small"  @click="uploadPicture"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span class="uploadText">{{MY_CONSTANT.Lang.uploadFile}}</span></el-button>
        <div style="color:#999;font-size: 12px;"  class="logoUpload">{{MY_CONSTANT.Lang.LogoUploadDesc+MY_CONSTANT.Upload.IdPhoto.size+'M'}}</div>
        <div v-show="ruleForm.press_card">
          <el-image
              class="avatar"
              style="width: 100px;height: auto;"
              :src="ruleForm.press_card"
              :preview-src-list="[ruleForm.press_card]"
              :initial-index="0"
              lazy
          />
        </div>

      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.personal_photo+'：'"  prop="personal_photo" class="el-form-item-display-block">

        <el-button  size="small"  @click="personaluploadPicture"><i class="el-icon-upload2"></i>&nbsp;&nbsp;<span class="uploadText">{{MY_CONSTANT.Lang.uploadFile}}</span></el-button>
        <div style="color:#999;font-size: 12px;"  class="logoUpload">{{MY_CONSTANT.Lang.LogoUploadDesc+MY_CONSTANT.Upload.IdPhoto.size+'M,'+MY_CONSTANT.Upload.IdPhoto.width+'*'+MY_CONSTANT.Upload.IdPhoto.height}}</div>
        <div v-show="ruleForm.personal_photo">
        <el-image
            class="avatar"
            style="width: 100px;height: auto;"
            :src="ruleForm.personal_photo"
            :preview-src-list="[ruleForm.personal_photo]"
            :initial-index="0"
            lazy
        />
        </div>
      </el-form-item>


      <el-form-item :label="'\n'" >
      <el-button type="danger"  size="large"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.submit }}</el-button>
      </el-form-item>
    </el-form>

  </div>

        <!-- 右边 -->
        <div class="about-right">
          <div class="about-right1" v-for="(item,key) in MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.exhibitor_info.list">
            <custom-link :style="{'color': this.$route.fullPath == '/'+MY_CONSTANT.setApiLang+item.url? 'var(--uavdeep-color)' : ''}" :to="item.url">
              <img src="../../../assets/img/icon/sanjiao.png">
              <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
            </custom-link>
          </div>

          <Components :datas= '{
        "Template/About-right": "",
         }'>
          </Components>

        </div>

      </div>

    </div>

  <!-- 剪裁组件弹窗 -->
  <el-dialog
      :title="MY_CONSTANT.Lang.Upload+MY_CONSTANT.Lang.pressCard"
      :destroy-on-close="true"
      v-model="cropperModel"
      width="95%"
      style="max-width: 1000px;margin-top: 30px;"
      center
  >

    <upload-cropper
        @uploadImgSuccess = "handleUploadSuccess"
        :width="MY_CONSTANT.Upload.IdPhoto.width"
        :height="MY_CONSTANT.Upload.IdPhoto.height"
        :title="MY_CONSTANT.Lang.pressCard"
        ref="child"
    >
    </upload-cropper>

  </el-dialog>

  <el-dialog
      :title="MY_CONSTANT.Lang.Upload+MY_CONSTANT.Lang.personal_photo"
      :destroy-on-close="true"
      v-model="personalcropperModel"
      width="95%"
      style="max-width: 1000px;margin-top: 30px;"
      center
  >

    <upload-cropper
        @uploadImgSuccess = "personalhandleUploadSuccess"
        :width="MY_CONSTANT.Upload.IdPhoto.width"
        :height="MY_CONSTANT.Upload.IdPhoto.height"
        :title="MY_CONSTANT.Lang.personal_photo"
        ref="child"
    >
    </upload-cropper>

  </el-dialog>


</template>

<script>
import Components from '../../../modules/Components.vue'
import validate from "@/assets/js/validation";
import api from "@/assets/js/api";
import uploadCropper from "./VueCropper";
export default {
  components: {
    Components,
    uploadCropper
  },
  data() {
    return {
      cropperModel:false,
      personalcropperModel:false,
      ruleForm: {
        organization_name: '',
        type: '',
        name: this.$getCookie('link_name')||'',
        position: this.$getCookie('company_pos')||'',
        mobile:this.$getCookie('mobile')||'',
        email:this.$getCookie('email')||'',
        reporter_number: '',
        id_type: '',
        id_number: '',
        press_card: '',
        personal_photo: '',
      },
      rules: {
        organization_name: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.media_organization_name'), trigger: 'blur'},

        ],
        type: [
          {  required: true, message:() => this.$MyConstant('Lang.PleaseSelectOne')+this.$MyConstant('Lang.media_type'), trigger: ['change','blur'] },
          { validator: validate.validateSelect, trigger: 'blur' },

        ],
        name: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.name'), trigger: 'blur'},

        ],
        position: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.position'), trigger: 'blur'},

        ],
        mobile: [
          { required: true,message:  () =>this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.mobile'), trigger: 'blur' },
          { validator: (rule, value, callback)=>{
              if(this.MY_CONSTANT.setApiLang== 'zh-cn'){
                validate.validateMobile(rule, value, callback)
              }
              callback();
            }, trigger: 'blur' },
        ],
        email: [
          { required: true, message:() => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message: this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: ['blur'] },

        ],
        reporter_number: [
        { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.media_reporter_number'), trigger: 'blur'},

      ],
        id_type: [
          { id_type: true, required: true, message: () =>this.$MyConstant('Lang.PleaseSelectOne')+this.$MyConstant('Lang.media_id_type'), trigger: ['change','blur'] },
          { validator: validate.validateSelect, trigger: 'blur' },

        ],
        id_number: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.id_number'), trigger: 'blur'},

        ],
        press_card: [
          { required: true,message: () => this.$MyConstant('Lang.PleaseUpload')+this.$MyConstant('Lang.pressCard'), trigger: ['change','blur']}
        ],
        personal_photo: [
          { required: true,message: () => this.$MyConstant('Lang.PleaseUpload')+this.$MyConstant('Lang.personal_photo'), trigger: ['change','blur']}
        ]

      }
    }
  },
  created() {

  },
  methods: {
    //ID照片上传
    uploadPicture(){

      this.cropperModel = true;

    },
    //图片上传成功后
    handleUploadSuccess (data){
      this.ruleForm.press_card = data;
      this.cropperModel = false;
    },
    //ID照片上传
    personaluploadPicture(){

      this.personalcropperModel = true;

    },
    //图片上传成功后
    personalhandleUploadSuccess (data){
      this.ruleForm.personal_photo = data;
      this.personalcropperModel = false;
    },
    strReplace(v,replacements='{}') {
      setTimeout(() => {

        // 要进行替换的原始字符串
        var originalString = this.ruleForm[v];

        // 使用 replace() 函数进行多字符串替换
        for (var key in replacements) {
          if (replacements.hasOwnProperty(key)) {
            originalString = originalString.replace(new RegExp(key, "g"), replacements[key]);

          }
        }

        //判断英文第一个是否存在空格
        var jiancha=originalString.charAt(0);
        if(jiancha==' ') {
          originalString= originalString.replace(jiancha,'');
        }

        this.ruleForm[v]=originalString;

      }, 1000);

    },
    async submitForm(formName) {

      await this.$refs[formName].validate((valid) => {

        if (valid) {
          api.ajaxbaseUrl.post('/api/MediaReg/add',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                // 处理响应数据
                if(response&&response.data.err==0){
                  this.$refs.ruleForm.resetFields()
                  this.ruleForm.email=''
                  this.ruleForm.position=''
                  this.ruleForm.mobile=''
                  this.ruleForm.name=''
                  this.$message.success({message:this.MY_CONSTANT.Lang.submit+this.MY_CONSTANT.Lang.success,offset:400,duration:3000});
                }
              })
              .catch(error => {
                // 处理错误
                console.log(error);
              })


        }else {

          this.$nextTick(() => {
            // 获取错误节点
            let isError = this.$refs[formName].$el.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })
          return false;
        }
      });

    },

  }
};
</script>
<style scoped>

</style>
