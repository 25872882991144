<template>
  <div class="banner">
    <div class="swiper-container swiper-container1">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <img src="../../../assets/img/sch.png">
        </div>
      </div>
    </div>
  </div>
  <div class="about">
    <div class="about-top">
      <p>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.name_'+MY_CONSTANT.sqlWhereLang) }}</p>
      <img src="../../../assets/img/icon/jiantou.png">
      <em>{{ this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list.reserve.name_'+MY_CONSTANT.sqlWhereLang) }}</em>

    </div>
    <div class="contentMiddle">
      <!-- 左边 -->
      <div class="contentLeft">
  <el-form v-if="this.$cookies.isKey('Cookie')" :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">
    <el-form-item :label="this.$MyConstant('Lang.CCompanyName')+'：'" prop="company">
      <!--                        '[^\\u4e00-\\u9fa5]': '',-->
      <el-input class="input400" @keyup.native="strReplace('cp_name',{' ': ''})" v-model="ruleForm.company" :placeholder="this.$MyConstant('Lang.exampleCCompanyName')" onkeyup="" ></el-input>

    </el-form-item>
    <el-form-item :label="MY_CONSTANT.Lang.ECompanyName+'：'" prop="company_en">

      <el-input class="input400"  v-model="ruleForm.company_en" @keyup.native="strReplace('company_en',{'[\\u4E00-\\u9FA5]': ''})" :placeholder="this.$MyConstant('Lang.exampleECompanyName')"></el-input>

    </el-form-item>


    <el-form-item :label="this.$MyConstant('Lang.linkman')+'：'" prop="linkman">
      <el-input class="input307" v-model="ruleForm.linkman"    :placeholder="this.$MyConstant('Lang.PleaseEnter')"    ></el-input>
      <!--                        @keyup.native="strReplace('linkman',{'[^\\u4e00-\\u9fa5A-Za-z]': ' ',' ': ''})"-->
    </el-form-item>


    <el-form-item :label="this.$MyConstant('Lang.mobile')+'：'" prop="mobile">
      <el-row class="input400">
        <el-col :span="11">
          <el-select-v2
              size="large"
              v-model="ruleForm.mobile_quhao"
              :options="worldJson"
              :props="{
                      label: 'country_name',
                      value: 'country_code',
                    }"
              placeholder="Please select"
              style="width: 100%"
              filterable
          />
        </el-col>
        <el-col :span="13">
          <el-input style="width: 100%;" v-model="ruleForm.mobile" @keyup.native="strReplace('mobile',{'\\D': ' ',' ': ''})" :placeholder="MY_CONSTANT.Lang.PleaseEnter"></el-input>
        </el-col>
      </el-row>
    </el-form-item>

    <el-form-item label="E-mail" prop="email">
      <el-input class="input307" v-model="ruleForm.email" :placeholder="this.$MyConstant('Lang.PleaseEnter')" @keyup.native="strReplace('email',{' ': ''})"></el-input>
    </el-form-item>

    <el-form-item :label="this.$MyConstant('Lang.quantity')+'：'" prop="quantity"   class="quantity"   >
      <el-input-number style="width: 150px" v-model="ruleForm.quantity" :min="1" :max="100"></el-input-number><br>
      <p style="display: contents;font-size: 12px;font-weight: normal;line-height: normal;letter-spacing: 0em;color: rgba(0, 0, 0, 0.4);">{{this.$MyConstant('Lang.quantityDesc')}}</p>
    </el-form-item>


<!--    <el-form-item :label="this.$MyConstant('Lang.intention_pavilion')+'：'" prop="intention_pavilion" class="Linheight28">-->

<!--      <el-radio-group v-model="ruleForm.intention_pavilion">-->
<!--        <el-radio v-for="item in this.$MyConstant('Lang.intention_pavilion_arr')"  style="width: 100%;margin: 7px;" :label="item" name="Intention_pavilion"></el-radio>-->
<!--      </el-radio-group>-->
<!--    </el-form-item>-->


<!--    <el-form-item :label="'\n'"  class="Linheight28">-->
<!--      <el-image-->
<!--          style="width:621px;"-->
<!--          src="https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20231114/98b00b19178b330fbf65c99e90c012eb.jpg"-->
<!--          :preview-src-list="['https://51kufei.oss-cn-shenzhen.aliyuncs.com/upload/20231114/98b00b19178b330fbf65c99e90c012eb.jpg']"-->
<!--          :initial-index="0"-->
<!--          lazy />-->

<!--    </el-form-item>-->
    <br><br>
    <el-form-item :label="'\n'" >
      <el-button size="large" v-if="this.$MyConstant('Api_CONSTANT.SizeConfig.site_pre_reg_closed')== 0 " type="danger"  @click="submitForm('ruleForm')" >{{this.$MyConstant('Lang.submit')}}</el-button>
    </el-form-item>

  </el-form>
</div>
      <!-- 右边 -->
      <div class="about-right">
        <div class="about-right1" v-for="(item,key) in this.$MyConstant('Api_CONSTANT.menu.Usermenu.exhibition.list')">
          <custom-link :style="{'color': 'reserve' == key ? 'var(--uavdeep-color)' : ''}" :to="item.url">
            <img src="../../../assets/img/icon/sanjiao.png">
            <em>{{ item['name_'+MY_CONSTANT.sqlWhereLang] }}</em>
          </custom-link>
        </div>

        <Components :datas= '{
        "Template/About-right": "",
         }'>
        </Components>

      </div>

    </div>

  </div>
</template>

<script>
import Components from '../../../modules/Components.vue'
import {validateUser, validateSelect, validateWorldMobile} from "@/assets/js/validation";
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      worldJson:[],
      ruleForm:{
        company:this.$getCookie('cp_name'),
        company_en:this.$getCookie('cp_en_name'),
        linkman:this.$getCookie('link_name'),
        mobile:this.$getCookie('mobile'),
        mobile_quhao:this.$getCookie('mobile_quhao'),
        email:this.$getCookie('email'),
        quantity:'1',
        intention_pavilion:''
      },
      rules: {
        company: [
          { required: this.$MyConstant('sqlWhereLang')=='cn', message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.CCompanyName'), trigger: 'blur'},
          { validator:  (rule, value, callback) => validateUser(rule, value, callback, 'cp_name'), trigger: 'blur' }
        ],
        company_en: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.ECompanyName'), trigger: 'blur'},
          { validator: (rule, value, callback) => validateUser(rule, value, callback, 'cp_en_name'), trigger: 'blur' }
        ],

        quantity: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.quantity'), trigger: 'blur'},
          {pattern: /\d+$/, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Num'),trigger: ['change','blur']},
        ],
        linkman: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.linkman'), trigger: 'blur'},
        ],
        mobile: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.mobile'), trigger: 'blur' },
          { validator:  (rule, value, callback) => validateWorldMobile(rule, value, callback,this.worldJson.find(item => item.country_code === this.ruleForm.mobile_quhao)), trigger: 'blur' },
        ],

        email: [
          { required: true, message: () => this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.Email'), trigger: 'blur'},
          { type: 'email', message: () => this.$MyConstant('Lang.PleaseCorrect')+this.$MyConstant('Lang.Email'), trigger: 'blur' },
          { validator: validateUser, trigger: 'blur' }
        ],
        intention_pavilion: [
          {required: true, message: () => this.$MyConstant('Lang.PleaseSelectOne'), trigger: ['change','blur'] },
          { validator: validateSelect, trigger: 'blur' },
        ],


      }
    }
    },
  watch: {
    'MY_CONSTANT.sqlWhereLang': {
      immediate: true, // 初始化时立即执行
      async handler() {

        this.rules.company[0].required=this.$MyConstant('sqlWhereLang')=='cn'

        const worldJson = await import(`../../../lang/world.json`);
        this.worldJson = worldJson.default.map((item) => {
          return {
            country_code: item.country_code,
            MobileRegularExp: item.MobileRegularExp,
            country_name: item['country_name_'+this.MY_CONSTANT.sqlWhereLang]+'('+item.country_code+')',
          };
        });

      }
    }
  },
 async created() {

   if(this.$MyConstant('Api_CONSTANT.SizeConfig.site_pre_reg_closed')==1){
     this.$message.error(this.$MyConstant('Lang.site_pre_reg_closed'));
   }

    const response= await  api.ajaxbaseUrlget('/api/BoothsReserve/info')
   if(response&&response.data.err==0){this.ruleForm=response.data.obj.data}
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.ajaxbaseUrl.post('/api/BoothsReserve/submit',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                if(response) {
                  this.$message.success({
                    message: response.data.msg,
                    offset: 400, duration: 3000
                  });
                }

              });
        }else {

          this.$nextTick(() => {
            // 获取错误节点
            let isError = this.$refs[formName].$el.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth',
            })
          })

          return false;
        }
      });

    },


  }
};
</script>

<style scoped>
/deep/.quantity .el-input__wrapper{
  width: 50px;

}
</style>
