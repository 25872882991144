<template>

  <Components :datas='{
        "Template/Header": "",
        "Template/PageMenu": {Pagetitle: [
          MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.about["name_"+MY_CONSTANT.sqlWhereLang],
          MY_CONSTANT.Api_CONSTANT.menu.PublicMenu.about.list.history["name_"+MY_CONSTANT.sqlWhereLang]
        ]}
      }'>
  </Components>


  <div class="middle">

    <el-row :gutter="24">
      <el-col :span="8" v-for="(item,index) in soncatList"
              style="display: flex;align-items: center;justify-content: center;max-width:100%;">
        <div style="">
            <custom-link   :to="'/History?id='+item.parent_id+'&son_cat_id='+item.h_cat_id">
              <el-button :disabled="item.h_cat_id == this.$route.query.son_cat_id"  color="var(--uavshallow-color)">{{ MY_CONSTANT.sqlWhereLang=='cn'?item.h_cat_name:item.h_en_cat_name }}</el-button>
            </custom-link>
        </div>
      </el-col>
    </el-row>


<!--        <el-image v-for="(item,key) in imgItems"-->
<!--            style="max-width: 100%;height: auto;margin-top: 10px;"-->
<!--            :src="item"-->
<!--            :preview-src-list="[item]"-->
<!--            :initial-index="key"-->
<!--        />-->


    <div  ref="imageContainer">
    <img v-for="(item,key) in imgItems"
         style="margin-top: 10px;max-width: 100%;height: auto;"
         :src="item.h_pic.includes('http')?item.h_pic:MY_CONSTANT.StaticPath+'UploadFiles/Images/Review/'+item.h_pic" :alt="item.h_name||'未命名'">
   </div>


  </div>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>
</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    return {
      cat_id:59,
      son_cat_id:59,
      catList:[],
      soncatList:[],
      imgItems:[],
      menu:this.$MyConstant('Api_CONSTANT.menu.PublicMenu.about.list')
    }
  },
  async created() {


  },
  methods: {
    async fetchData() {

      const response = await    api.ajaxbaseUrlget('/api/history/GetCat');
      this.catList= response.data.obj.data
      this.cat_id=this.$route.query.id?this.$route.query.id:this.catList[0].h_cat_id
      const response1 = await    api.ajaxbaseUrlget('/api/history/GetCat',{parent_id:this.cat_id});
      this.soncatList= response1.data.obj.data
      this.son_cat_id=this.$route.query.son_cat_id?this.$route.query.son_cat_id:this.soncatList[0].h_cat_id
      // console.log(this.catList[0]);


       const response2 = await    api.ajaxbaseUrlget('/api/history/GetHistory',{cat_id:this.son_cat_id});
       this.imgItems= response2.data.obj.data
      console.log(response2.data.obj.data);
      // let imgItems=[];
      // response2.data.obj.data.map((item, index) => {
      //   imgItems[index]=item.h_pic.includes('http')?item.h_pic:this.$MyConstant('StaticPath')+'UploadFiles/Images/Review/'+item.h_pic
      //
      // });
      // this.imgItems=imgItems

      this.$nextTick(() => {
        new this.$viewer(this.$refs.imageContainer);
      });


    }
  },

mounted() {


},
  watch: {
    '$route': {
      immediate: true, // 初始化时立即执行
      handler() {
        // 当路由参数变化时，重新请求接口并更新内容
        this.fetchData();
      }
    }
  },
};
</script>
<style scoped>

/* 6 历史回顾about_history*/
.about-history-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 134px;
}

.about-history-left {
  width: 75%;
  margin-top: 47px;
}

.about-history-left ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 24px;

}

.about-history-left ul li {
  width: 17%;
  text-align: center;
  padding: 14px 0 11px;
  background-color: #F5F5F5;
  margin-right: 21px;
  margin-bottom: 21px;

  font-size: 21px;

  font-weight: 500;

  color: #8B8B8B;
  line-height: 35px;

}


.tab .tab1 {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.tab .tab1::after {
  content: "";
  display: block;
  width: 209px;
  height: 118px;
  visibility: hidden;
}

.tab .tab1 div {
  /* width: 209px;
  height: 118px; */
  margin-bottom: 10px;
  margin-right: 10px;
}

.tab .tab1 img {
  width: 400px;
  height: 250px;
  /*  max-width: 209px;
   max-height: 118px; */
}
</style>
