<template>
  <Components :datas='{
        "Template/Header": "",
        "Template/PageMenu": {Pagetitle: [
          MY_CONSTANT.Api_CONSTANT.menu.Usermenu.exhibition["name_"+MY_CONSTANT.sqlWhereLang],
          MY_CONSTANT.Api_CONSTANT.menu.Usermenu.exhibition.list.edit_password["name_"+MY_CONSTANT.sqlWhereLang]
        ]}
      }'>
  </Components>

  <div class="middle">

    <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForm"  class="demo-ruleForm" id="register_form">

      <el-form-item size="large" :label="MY_CONSTANT.Lang.OldPassword+'：'"  prop="oldpassword">
        <el-input class="input400" v-model="ruleForm.oldpassword" :placeholder="MY_CONSTANT.Lang.PleaseEnter" show-password></el-input>
      </el-form-item>

      <el-form-item size="large" :label="MY_CONSTANT.Lang.NewPassword+'：'"  prop="password">
        <el-input class="input400" v-model="ruleForm.password" :placeholder="MY_CONSTANT.Lang.PleaseEnter" show-password></el-input>
      </el-form-item>

      <el-form-item size="large"  :label="MY_CONSTANT.Lang.Confirm+' '+MY_CONSTANT.Lang.NewPassword+'：'" prop="checkPass">
        <el-input class="input400" v-model="ruleForm.checkPass" :placeholder="MY_CONSTANT.Lang.PleaseEnter" show-password></el-input>
      </el-form-item>
      <el-button style="width: 100%;margin-top:41px;"  size="large"  @click="submitForm('ruleForm')" >{{ MY_CONSTANT.Lang.submit }}</el-button>

    </el-form>


  </div>

  <br>
  <Components :datas='{
        "Template/Footer": "",
      }'>
  </Components>

</template>

<script>
import Components from '../../../modules/Components.vue'
import api from "@/assets/js/api";
export default {
  components: {
    Components
  },
  data() {
    const validateNewPass= (rule, value, callback) => {
      if(value==this.ruleForm.oldpassword){
        callback(new Error(this.$MyConstant('Lang.NewOldRepeat')));
      }
      return  callback();
    };

    const validateTruePass= (rule, value, callback) => {
      if(value!=this.ruleForm.password){
        callback(new Error(this.$MyConstant('Lang.TwoPassword')));
      }
      return  callback();
    };
    return {
      ruleForm: {
        oldpassword:'',
        password: '',
        checkPass: '',
      },
      rules: {
        oldpassword: [
          {required: true, message:()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.OldPassword'), trigger: 'blur'},
          {min: 6, max: 30, message:()=> this.$MyConstant('Lang.PasswordLength'), trigger: 'blur'},
        ],
        password: [
          {required: true, message:()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.NewPassword'), trigger: 'blur'},
          {min: 6, max: 30, message:()=> this.$MyConstant('Lang.PasswordLength'), trigger: 'blur'},
          { validator: validateNewPass, trigger: 'blur' },
        ],
        checkPass: [
          {required: true, message:()=>  this.$MyConstant('Lang.PleaseEnter')+this.$MyConstant('Lang.NewPassword'), trigger: 'blur'},
          {min: 6, max: 30, message:()=> this.$MyConstant('Lang.PasswordLength'), trigger: 'blur'},
          { validator: validateTruePass, trigger: 'blur' },
        ]
      }
    }
  },

  methods: {
    async submitForm(formName) {

      await this.$refs[formName].validate((valid) => {

        if (valid) {
          api.ajaxbaseUrl.post('/api/User/ChangePassword',this.ruleForm,{headers:{'Accept-Language':this.MY_CONSTANT.setApiLang}})
              .then(response => {
                if(response.data.err==0)    this.$message.success({message:response.data.msg,offset:250,duration:1000});
              })

        }
      });

    },

  }
};
</script>
<style scoped>


</style>
