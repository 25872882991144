<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.outputSize"
            :outputType="option.outputType"
            :info="option.info"
            :canScale="option.canScale"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :full="option.full"
            :fixedBox="option.fixedBox"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :centerBox="option.centerBox"
            :height="option.height"
            :infoTrue="option.infoTrue"
            :maxImgSize="option.maxImgSize"
            :enlarge="option.enlarge"
            :mode="option.mode"
            @realTime="realTime"
            @imgLoad="imgLoad">
        </vue-cropper>
      </div>
      <!--底部操作工具按钮-->
      <div class="footer-btn">
        <div class="scope-btn">
          <label class="btn" for="uploads">{{ MY_CONSTANT.Lang.PleaseSelect }} {{ title }}</label>
          <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
          <el-button size="small" type="danger" plain @click="changeScale(1)">{{ MY_CONSTANT.Lang.BlowUp }}</el-button>
          <el-button size="small" type="danger" plain @click="changeScale(-1)">{{ MY_CONSTANT.Lang.Minification }}</el-button>
          <el-button size="small" type="danger" plain @click="rotateLeft"><el-icon><RefreshLeft /></el-icon></el-button>
          <el-button size="small" type="danger" plain @click="rotateRight"><el-icon><RefreshRight /></el-icon></el-button>
        </div>
        <div class="upload-btn">
          <el-button size="small" type="success" @click="uploadImg('blob')">{{ MY_CONSTANT.Lang.Upload }} {{ title }} <i class="el-icon-upload"></i></el-button>
        </div>
      </div>
    </div>
    <!--预览效果图-->
    <div class="show-preview">
      <div :style="previews.div" class="preview">
        <img :src="previews.url" :style="previews.img">
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import api from "@/assets/js/api";
export default {
  components: {
    VueCropper
  },
  props: {
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    },
    title: {
      type: String,
      default: 'logo'
    }
  },
  data() {
    return {
      previews: {},
      option:{
        img: '',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: true,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        autoCropWidth: 300,  //默认生成截图框宽度
        autoCropHeight: 300, //默认生成截图框高度
        fixed: true,         //是否开启截图框宽高固定比例
        fixedNumber: [1, 1], //截图框的宽高比例
        full: false,         //false按原比例裁切图片，不失真
        fixedBox: true,      //固定截图框大小，不允许改变
        canMove: false,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: false,     //上传图片按照原始比例渲染
        centerBox: false,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,    //限制图片最大宽度和高度
        enlarge: 1,          //图片根据截图框输出比例倍数
        mode: '300px 300px'  //图片默认渲染方式
      }
    };
  },
  created() {
    this.option.autoCropWidth=this.width
    this.option.autoCropHeight=this.height
    this.option.fixedNumber=[this.width,this.height]
    this.option.mode=this.width+'px '+this.height+'px'


  },
  methods: {
    //初始化函数
    imgLoad (msg) {
      console.log("工具初始化函数====="+msg)
    },
    //图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    //向左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    //实时预览函数
    realTime (data) {
      this.previews = data
    },
    //选择图片
    selectImg (e) {
      let file = e.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png',
          type: "error"
        });
        return false
      }
      //转化为blob
      let reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      //转化为base64
      reader.readAsDataURL(file)
    },
    //上传图片
    uploadImg (type) {
      let _this = this;

      if (type === 'blob') {

        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          formData.append('file', data, this.$getCookie('cp_name')+'_logo.png');

          try {
            const response = await api.ajaxbaseUrl.post('/api/upload/a', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });

            // 处理上传成功后的逻辑
            _this.$emit('uploadImgSuccess', response.data.obj.data.url);
          } catch (error) {
            // 处理上传失败后的逻辑
            console.error(error);
          }
        });

      }

    },
  },
}
</script>

<style scoped>
.cropper-content{
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
}
.cropper-box{
  flex: 1;
  width: 100%;

}
.cropper{
  width: auto;
  height: 300px;
}

.show-preview{
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.preview{
  overflow: hidden;
  border:1px solid #67c23a;
  /*background: #cccccc;*/
  background: transparent;
}
.footer-btn{
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
}
.upload-btn{
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
}
.scope-btn{
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  padding-right: 10px;
}
.btn {
  outline: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  -webkit-transition: .1s;
  transition: .1s;
  font-weight: 500;
  padding: 6px 6px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409EFF;
  border-color: #409EFF;
  margin-right: 10px;
}
</style>